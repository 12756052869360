import {
  Card,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useMemo } from 'react';

import { CONTRACT_STATUS } from '@/constants/app';
import { dateBetweenRange, isToday, stringDateToUtc } from '@/utils/date';
import { ROUTES } from '@/constants/routes';
import { InfoIcon } from '@chakra-ui/icons';

type Props = {
  data: UnitCalendar[];
  year: number;
};

const ChessMode = ({ data, year }: Props) => {
  const { t } = useTranslation();

  const bg = useColorModeValue('white', 'navy.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const cellColor = 'white';
  const cellPadding = '0 5px';

  const calendar = useMemo(() => getMonth(year), [year]);

  const tableRef = useRef<HTMLDivElement>(null);

  const currentDate = useMemo(() => new Date(), []);
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const dayWidth = 30;

  useEffect(() => {
    if (tableRef.current && year === currentDate.getFullYear()) {
      const scrollPosition = (currentMonth * 31 + currentDay) * dayWidth;
      tableRef.current.scrollLeft = scrollPosition - 118;
    }
  }, [currentDate, currentDay, currentMonth, year, data]);

  return (
    <Card my="20px" flex={1} gap="20px">
      <Flex direction="row" overflowX="auto" flex={1} ref={tableRef}>
        <Table
          variant="simple"
          height="1px"
          border="1px"
          borderColor="transparent"
          outlineColor={'transparent'}
        >
          <Thead>
            <Tr>
              <Th
                whiteSpace="nowrap"
                position="sticky"
                left="-2px"
                zIndex="1"
                bg={bg}
                borderLeft="1px solid"
                borderRight="1px solid"
                borderTop="1px solid"
                borderColor={borderColor}
              >
                {t('units.name')}
              </Th>

              {calendar.map(({ days }, i) => (
                <Th
                  key={i}
                  colSpan={days}
                  textAlign="center"
                  borderRight="1px solid"
                  borderTop="1px solid"
                  borderColor={borderColor}
                >
                  {t(`month_by_number.${i}`)}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {data?.map(({ contracts, _id, name }) => {
              const info = getUnitInfo(contracts, year);

              return (
                <Tr key={_id}>
                  <Td
                    whiteSpace="nowrap"
                    position="sticky"
                    left="-2px"
                    zIndex="1"
                    bg={bg}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderTop="1px solid"
                    borderColor={borderColor}
                    p={cellPadding}
                    textAlign="center"
                    h="35px"
                  >
                    <Flex align="center" justify="center" gap="5px">
                      {name}

                      <Tooltip
                        maxW="1000px"
                        label={
                          <Flex direction="column" gap="2px">
                            <Text>
                              {t('units.busyDays')}: {info.busyDays}
                            </Text>
                            <Text>
                              {t('units.freeDays')}: {info.freeDays}
                            </Text>
                            <Text>
                              {t('units.occupationPercentage')}:{' '}
                              {info.occupationPercentage}%
                            </Text>
                          </Flex>
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Flex>
                  </Td>

                  {calendar.map(({ days }, i) =>
                    Array.from({ length: days }, (_, day) => day + 1).map((day) => {
                      const { color, contract } = getCellColor(
                        `${year}-${i + 1}-${day}`,
                        contracts,
                      );

                      return (
                        <Td
                          textAlign="center"
                          key={`${year}-${i + 1}-${day}`}
                          borderRight="1px solid"
                          borderColor={borderColor}
                          p={cellPadding}
                          fontSize="xs"
                          minW={dayWidth}
                          h="25px"
                          bg={color}
                          color={!contract ? 'inherit' : cellColor}
                          cursor={!!contract ? 'pointer' : 'default'}
                          onClick={() => {
                            if (!!contract) {
                              window.open(
                                `${ROUTES.CONTRACTS}/${contract.contractId}`,
                                '_blank',
                              );
                            }
                          }}
                        >
                          {!!contract ? (
                            <Tooltip
                              label={`${contract?.client?.name} ${contract?.client?.lastName}`}
                              placement="top"
                            >
                              {day}
                            </Tooltip>
                          ) : (
                            day
                          )}
                        </Td>
                      );
                    }),
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>

      <Flex w="100%" gap="20px">
        <Flex gap="10px" alignItems="center">
          <Flex bg="brand.500" w="40px" h="20px" />-<Text>{t('units.currentDay')}</Text>
        </Flex>

        <Flex gap="10px" alignItems="center">
          <Flex bg="green.500" w="40px" h="20px" />-
          <Text>{t(`contracts.statuses.${CONTRACT_STATUS.ACTIVE}`)}</Text>
        </Flex>

        <Flex gap="10px" alignItems="center">
          <Flex bg="red.500" w="40px" h="20px" />-
          <Text>
            {t(`contracts.statuses.${CONTRACT_STATUS.OVERDUE}`)} /{' '}
            {t(`contracts.statuses.${CONTRACT_STATUS.CLOSED}`)}
          </Text>
        </Flex>

        <Flex gap="10px" alignItems="center">
          <Flex bg="yellow.500" w="40px" h="20px" />-
          <Text>{t(`contracts.statuses.${CONTRACT_STATUS.PAUSED}`)}</Text>
        </Flex>

        <Flex gap="10px" alignItems="center">
          <Flex bg="gray.400" w="40px" h="20px" />-
          <Text>
            {t(`contracts.statuses.${CONTRACT_STATUS.DRAFT}`)} /{' '}
            {t(`contracts.statuses.${CONTRACT_STATUS.PENDING_SIGNATURE}`)}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

const getMonth = (year: number) => {
  const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const months = [
    { days: 31 },
    { days: isLeapYear(year) ? 29 : 28 },
    { days: 31 },
    { days: 30 },
    { days: 31 },
    { days: 30 },
    { days: 31 },
    { days: 31 },
    { days: 30 },
    { days: 31 },
    { days: 30 },
    { days: 31 },
  ];

  if (year === currentYear) {
    return months.slice(currentMonth);
  } else {
    return months;
  }
};

const getCellColor = (
  date: string,
  contracts: UnitCalendar['contracts'][number][],
): {
  color: string;
  contract: UnitCalendar['contracts'][number] | null;
} => {
  const _date = stringDateToUtc(new Date(date).toISOString());

  const isCurrentDay = isToday(new Date(date));

  const contract = contracts.find((contract) => {
    return dateBetweenRange(_date, contract.startDate, contract.endDate);
  });

  if (isCurrentDay) {
    return { color: 'brand.500', contract: contract ?? null };
  }

  if (!contract) {
    return { color: 'transparent', contract: null };
  }

  if (contract.status === CONTRACT_STATUS.ACTIVE) {
    return { color: 'green.500', contract };
  } else if (contract.status === CONTRACT_STATUS.PAUSED) {
    return { color: 'yellow.500', contract };
  } else if (
    contract.status === CONTRACT_STATUS.DRAFT ||
    contract.status === CONTRACT_STATUS.PENDING_SIGNATURE
  ) {
    return { color: 'gray.400', contract };
  }

  return { color: 'red.700', contract };
};

const getUnitInfo = (contracts: UnitCalendar['contracts'][number][], year: number) => {
  const date = new Date();
  const endOfYear = new Date(year, 11, 31);
  const totalDays = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 366 : 365;

  const busyDays = contracts.reduce((acc, contract) => {
    const contractStart = new Date(contract.startDate);
    const contractEnd = new Date(contract.endDate);

    if (contractEnd < new Date(date) || contractStart > endOfYear) {
      return acc;
    }

    const start = contractStart < new Date(date) ? new Date(date) : contractStart;
    const end = contractEnd > endOfYear ? endOfYear : contractEnd;

    return acc + Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
  }, 0);

  const freeDays = totalDays - busyDays;
  const occupationPercentage = Math.round((busyDays / totalDays) * 100);

  return {
    busyDays,
    freeDays,
    totalDays,
    occupationPercentage,
  };
};

export default ChessMode;
