import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Badge, Flex, HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { MdReport } from 'react-icons/md';
import { IoReload } from 'react-icons/io5';
import { FaCheck, FaEnvelope } from 'react-icons/fa';
import { CloseIcon } from '@chakra-ui/icons';

import DataTable from '@/components/Table';
import { getComplains, changeStatus } from '@/api/complains';
import Pagination from '@/components/Pagination';
import usePageInfo from '@/hooks/usePageInfo';
import usePagination from '@/hooks/usePagination';
import useRequestState from '@/hooks/useRequestState';
import ActionButton from '@/components/ActionButton';
import { formatDate } from '@/utils/date';
import { COMPLAIN_STATUS } from '@/constants/app';
import ReplyModal from './components/ReplyModal';
import ActionBar from '@/components/ActionBar';
import AppStore from '@/stores/app';

const ComplainsPage = () => {
  usePageInfo({ title: 'pages.communication' });

  const [selected, setSelected] = useState<Complain | null>(null);

  const { t } = useTranslation();
  const { selectedProperty } = AppStore;

  const { page, limit, sortBy, order, search, setPage, toggleOrder, setLimit } =
    usePagination<Client>({
      initialSortBy: 'createdAt',
      initialOrder: 'desc',
    });

  const { data, trigger: dataTrigger } = useRequestState<PaginateResult<Complain>>(
    () => getComplains({ page, limit, sortBy, order, search }),
    [page, limit, sortBy, order, search, selectedProperty],
  );

  const { trigger: statusTrigger } = useRequestState<
    'ok',
    { id: string; status: ComplainStatus }
  >(
    (params) => {
      return changeStatus(params?.id!, params?.status!);
    },
    [],
    { condition: false, onSuccess: dataTrigger },
  );

  const handleClose = () => {
    setSelected(null);
  };

  return (
    <>
      <Flex direction="column" h="100%">
        <ActionBar
          stats={
            <ActionButton
              mini
              hoverable={false}
              name={t('clients.total')}
              value={`${data?.totalDocs ?? '-'}`}
              icon={MdReport}
            />
          }
          actions={
            <>
              <ActionButton mini icon={IoReload} onClick={dataTrigger} />
            </>
          }
        />

        <DataTable<Complain, 'language' | 'actions'>
          mt="20px"
          numerable
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          columns={[
            {
              id: 'status',
              header: t('complains.status'),
              accessor: 'status',
              isSortable: true,
              cell: (complain) => (
                <Badge colorScheme={getStatusColor(complain.status)}>
                  {t(`complains.statuses.${complain.status}`)}
                </Badge>
              ),
            },
            {
              id: 'message',
              header: t('complains.message'),
              accessor: 'message',
              isSortable: true,
              maxW: '100px',
            },
            {
              id: 'client',
              header: t('complains.client'),
              accessor: 'client',
              isSortable: true,
              cell: (complain) => (
                <>{`${complain.client?.name} ${complain.client?.lastName}`}</>
              ),
            },
            {
              id: 'language',
              header: t('complains.language'),
              accessor: 'language',
              cell: (complain) => <>{t(`languages.${complain.client?.language}`)}</>,
            },
            {
              id: 'createdAt',
              header: t('complains.createdAt'),
              accessor: 'createdAt',
              isSortable: true,
              cell: (complain) => (
                <>{formatDate(complain.createdAt, 'DD-MM-YYYY HH:mm')}</>
              ),
            },
            {
              id: 'closedAt',
              header: t('complains.closedAt'),
              accessor: 'closedAt',
              cell: (complain) => (
                <>{formatDate(complain.closedAt, 'DD-MM-YYYY HH:mm')}</>
              ),
            },
            {
              id: 'actions',
              header: t('complains.actions'),
              accessor: 'actions',
              center: true,
              cell: (complain) => (
                <HStack justify="center">
                  <Tooltip label={t('complains.reply')}>
                    <IconButton
                      aria-label="Reply"
                      size="sm"
                      icon={<FaEnvelope />}
                      onClick={() => setSelected(complain)}
                      disabled={complain.status !== COMPLAIN_STATUS.PENDING}
                    />
                  </Tooltip>

                  <Tooltip label={t('complains.set_resolved')}>
                    <IconButton
                      aria-label="Resolve"
                      size="sm"
                      icon={<FaCheck />}
                      disabled={complain.status !== COMPLAIN_STATUS.PENDING}
                      onClick={() =>
                        statusTrigger({
                          id: complain._id,
                          status: COMPLAIN_STATUS.RESOLVED,
                        })
                      }
                    />
                  </Tooltip>

                  <Tooltip label={t('complains.set_rejected')}>
                    <IconButton
                      aria-label="Reject"
                      size="sm"
                      disabled={complain.status !== COMPLAIN_STATUS.PENDING}
                      icon={<CloseIcon fontSize="2xs" />}
                      onClick={() =>
                        statusTrigger({
                          id: complain._id,
                          status: COMPLAIN_STATUS.REJECTED,
                        })
                      }
                    />
                  </Tooltip>
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <ReplyModal data={selected} handleOnClose={handleClose} />
    </>
  );
};

const getStatusColor = (status: ComplainStatus) => {
  switch (status) {
    case COMPLAIN_STATUS.PENDING:
      return 'orange';
    case COMPLAIN_STATUS.RESOLVED:
      return 'green';
    case COMPLAIN_STATUS.REJECTED:
      return 'red';
  }
};

export default observer(ComplainsPage);
