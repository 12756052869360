import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Badge, Flex, HStack, Icon, IconButton, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdOpenInNew, MdRefresh } from 'react-icons/md';
import { IoAdd, IoReload } from 'react-icons/io5';
import { FaDownload, FaTrash } from 'react-icons/fa';
import { TbReceipt2 } from 'react-icons/tb';

import { getFileList, deleteFile, downloadFile } from '@/api/files';
import useRequestState from '@/hooks/useRequestState';
import DataTable from '@/components/Table';
import { ACCEPT_FILE_TYPES, FILE_COLLECTIONS } from '@/constants/app';
import { bitsToMegaBytes, getReadabeExtention } from '@/utils/format';
import UploadFileModal from './UploadFile';
import { formatDate } from '@/utils/date';
import usePagination from '@/hooks/usePagination';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import ActionBar from '@/components/ActionBar';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import { ROUTES } from '@/constants/routes';
import { showToast } from '@/utils/toast';

type Props = {
  title?: string;
  folder?: string;
  group?: IFileCollections;
  fileNumberLimit?: number;
  maxH?: string;
  miniActions?: boolean;
  layout?: {
    search?: boolean;
    total?: boolean;
    add?: boolean;
    reload?: boolean;
    filters?: boolean;
  };
};

const FilesTable = ({
  title,
  folder,
  group,
  maxH,
  miniActions = false,
  layout = {
    search: true,
    total: true,
    add: true,
    reload: true,
    filters: true,
  },
  fileNumberLimit,
}: Props) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const { t } = useTranslation();
  const { selectedProperty } = AppStore;

  const {
    page,
    limit,
    sortBy,
    order,
    search,
    setPage,
    toggleOrder,
    setLimit,
    setSearch,
  } = usePagination<IFile, keyof IFile>({
    initialSortBy: 'createdAt',
    initialOrder: 'desc',
  });

  const { data, trigger: getFilesTrigger } = useRequestState<PaginateResult<IFile>>(
    () => getFileList({ page, limit, sortBy, order, search }, { group, folder }),
    [page, limit, sortBy, order, selectedProperty, search],
  );

  const { trigger: deleteFileTrigger, loading: deleteLoading } = useRequestState<'ok'>(
    (_id) => deleteFile(_id as string),
    [],
    { condition: false, onSuccess: getFilesTrigger },
  );

  const handleDelete = async (file: IFile) => {
    deleteFileTrigger(file._id);
  };

  const handleDownload = async (file: IFile) => {
    const data = await downloadFile(file._id);

    if (data.success) {
      window.open(data.data, '_blank');
    }
  };

  const openFileUrl = ({ group, folder }: IFile) => {
    if (!group) return;

    if (group === FILE_COLLECTIONS.CONTRACTS) {
      return `${ROUTES.CONTRACTS}/${folder}`;
    } else if (group === FILE_COLLECTIONS.UNITS) {
      return `${ROUTES.UNITS}/${folder}`;
    }
  };

  const handleAddClick = () => {
    if (fileNumberLimit && data?.totalDocs && data?.totalDocs >= fileNumberLimit) {
      showToast(t('files.file_number_limit', { number: fileNumberLimit }));
    } else {
      setPopupOpen(true);
    }
  };

  const getMiniActions = () => {
    if (!miniActions) return undefined;

    const actions = [];

    if (data && layout.add) {
      actions.push({ icon: IoAdd, onClick: handleAddClick });
    }

    if (data && layout.reload) {
      actions.push({ icon: MdRefresh, onClick: getFilesTrigger });
    }

    return actions;
  };

  return (
    <>
      <Flex direction="column" h="100%">
        {!miniActions && (
          <ActionBar
            mb="20px"
            setSearch={setSearch}
            stats={
              <ActionButton
                mini
                hoverable={false}
                name={t('invoices.total')}
                value={data?.totalDocs ?? '-'}
                icon={TbReceipt2}
              />
            }
            actions={
              <>
                {layout.add && (
                  <ActionButton mini icon={IoAdd} onClick={handleAddClick} />
                )}

                {layout.reload && (
                  <ActionButton mini icon={IoReload} onClick={getFilesTrigger} />
                )}
              </>
            }
          />
        )}

        <DataTable<IFile>
          title={title}
          sortBy={sortBy}
          order={order}
          onSort={toggleOrder}
          data={data?.docs || []}
          actions={getMiniActions()}
          maxH={maxH}
          search={
            miniActions && layout?.search ? (
              <Search
                mini
                reverseColor
                onChange={setSearch}
                placeholder="invoices.search_by_client"
              />
            ) : undefined
          }
          columns={[
            {
              id: 'type',
              header: t('files.type'),
              accessor: 'type',
              isSortable: true,
              center: true,
              cell: (data: IFile) => {
                const ext = getReadabeExtention(data.type);

                return (
                  <Badge colorScheme={getFileTypeColor(ext)}>
                    {getReadabeExtention(data.type)}
                  </Badge>
                );
              },
            },
            {
              id: 'name',
              header: t('files.name'),
              accessor: 'name',
              isSortable: true,
              maxW: '500px',
            },
            !group
              ? {
                  id: 'folder',
                  header: t('files.attached_to'),
                  accessor: 'folder',
                  isSortable: true,
                  center: true,
                  cell: (data: IFile) => (
                    <Link href={openFileUrl(data)} target="_blank">
                      {t(`files.attached_to_group.${data.group}`)}

                      <Icon as={MdOpenInNew} ml="5px" top="3px" position="relative" />
                    </Link>
                  ),
                }
              : null,
            {
              id: 'size',
              header: t('files.size'),
              accessor: 'size',
              center: true,
              isSortable: true,
              cell: (data: IFile) => <>{bitsToMegaBytes(data.size)}</>,
            },
            {
              id: 'createdAt',
              header: t('files.created_at'),
              accessor: 'createdAt',
              isSortable: true,
              center: true,
              cell: (data: IFile) => <Text>{formatDate(data.createdAt)}</Text>,
            },
            {
              id: 'actions',
              header: t('invoices.actions'),
              accessor: null,
              center: true,
              cell: (data: IFile) => (
                <HStack justify="center">
                  <IconButton
                    aria-label="Download"
                    size="sm"
                    icon={<FaDownload />}
                    onClick={() => handleDownload(data)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    disabled={deleteLoading}
                    icon={<FaTrash />}
                    onClick={() => handleDelete(data)}
                  />
                </HStack>
              ),
            },
          ]}
          pagination={
            <Pagination
              {...data}
              onPageSizeChange={setLimit}
              onPageChange={setPage}
              limit={limit}
            />
          }
        />
      </Flex>

      <UploadFileModal
        isOpen={popupOpen}
        folder={folder}
        group={group}
        setOpen={setPopupOpen}
        dataTrigger={getFilesTrigger}
        accept={{
          ...ACCEPT_FILE_TYPES.IMAGES,
          ...ACCEPT_FILE_TYPES.PDF,
          ...ACCEPT_FILE_TYPES.EXCEL,
          ...ACCEPT_FILE_TYPES.CSV,
          ...ACCEPT_FILE_TYPES.DOC,
        }}
      />
    </>
  );
};

export const getFileTypeColor = (ext: string) => {
  switch (ext.toLowerCase()) {
    case 'pdf':
      return 'red';
    case 'doc':
    case 'docx':
      return 'blue';
    case 'xls':
    case 'xlsx':
      return 'green';
    case 'csv':
      return 'gray';
    default:
      return 'orange';
  }
};

export default observer(FilesTable);
