import { TFunction } from 'i18next';

import {
  INVOICE_ITEM_TYPE,
  INVOICE_STATUS,
  PAYMENT_CIRCLE,
  UPAID_INVOICE_STATUSES,
} from '@/constants/app';
import { formatToTwoDecimals } from './format';
import { daysInMonthByPeriod } from './date';

export const mapInvoicesToColor = (status: InvoiceStatus) => {
  switch (status) {
    case 'PENDING':
      return 'yellow';
    case 'PAID':
      return 'green';
    case 'REFUNDED':
      return 'blue';
    case 'OVERDUE':
      return 'red';
    case 'CANCELLED':
      return 'orange';
    default:
      return 'gray';
  }
};

export const mapTypeToColor = (type: InvoiceType) => {
  switch (type) {
    case 'REGULAR':
      return 'green';
    case 'CUSTOM':
      return 'blue';
    default:
      return 'gray';
  }
};

export const mapPaymentToColor = (payment: PaymentMethod) => {
  switch (payment) {
    case 'ONLINE':
      return 'green';
    case 'BANK_TRANSFER':
      return 'blue';
    case 'CASH':
      return 'yellow';
    case 'CRYPTO':
      return 'purple';
    case 'MOBILE_TRANSFER':
      return 'orange';
    case 'TERMINAL':
      return 'pink';
    default:
      return 'gray';
  }
};

export const getInvoiceChartData = (
  statsData: InvoicesStats | undefined,
  t: TFunction,
) => {
  if (!statsData) return [];

  return Object.keys(statsData).map((key) => ({
    label: t(`invoices.statuses.${key}`),
    value: statsData[key as keyof InvoicesStats],
  }));
};

export const getInvoicesLegendChart = (
  statsData: InvoicesStats | undefined,
  t: TFunction,
) => {
  if (!statsData) return [];

  const data = Object.keys(statsData).reduce(
    (acc, _key) => {
      const key = _key as InvoiceStatus;
      const value = statsData[key];

      if (UPAID_INVOICE_STATUSES.includes(key)) {
        acc.UNPAID += value;
      } else if (key === INVOICE_STATUS.PAID) {
        acc.PAID += value;
      } else {
        acc.OTHER += value;
      }

      return acc;
    },
    {
      PAID: 0,
      UNPAID: 0,
      OTHER: 0,
    },
  );

  const total = Object.values(data).reduce((acc, val) => acc + val, 0);

  return [
    {
      label: t('contract_details.paid_invoices'),
      value: data.PAID,
      percent: ((data.PAID / total) * 100).toFixed(0),
    },
    {
      label: t('contract_details.unpaid_invoices'),
      value: data.UNPAID,
      percent: ((data.UNPAID / total) * 100).toFixed(0),
    },
    {
      label: t('contract_details.other_invoices'),
      value: data.OTHER,
      percent: ((data.OTHER / total) * 100).toFixed(0),
    },
  ];
};

type CalculatedItemsType = {
  items: InvoiceItem[];
  totalAmount: number;
  totalDays: number;
};

export const calculateItemsCost = (
  items: CalendarValue,
  contractPrice: number,
  type: PaymentCircle,
): CalculatedItemsType => {
  if (!items) {
    return {
      items: [],
      totalAmount: 0,
      totalDays: 0,
    };
  }

  const _items = daysInMonthByPeriod(items);

  return _items.reduce(
    (acc, curr, i) => {
      const { left, total, year, month } = curr;
      let dayPrice = 0;

      if (type === PAYMENT_CIRCLE.WEEKLY) {
        dayPrice = contractPrice / 7;
      } else if (type === PAYMENT_CIRCLE.MONTHLY) {
        dayPrice = contractPrice / total;
      } else if (type === PAYMENT_CIRCLE.DAILY) {
        dayPrice = contractPrice;
      }

      const price = dayPrice * left;

      acc.totalAmount += price;
      acc.totalDays += left;

      acc.items.push({
        type: INVOICE_ITEM_TYPE.REGULAR,
        quantity: left,
        price: formatToTwoDecimals(price),
        dayPrice: formatToTwoDecimals(dayPrice),
        translation: {
          key: 'invoice.payment_for',
          params: {
            date: `${month}-${year}`,
          },
        },
      });

      if (i === _items.length - 1) {
        acc.totalAmount = formatToTwoDecimals(acc.totalAmount);
      }

      return acc;
    },
    {
      items: [],
      totalAmount: 0,
      totalDays: 0,
    } as CalculatedItemsType,
  );
};

export const getPaymentMethodsChartData = (
  data: PaymentMethodStats | undefined,
  t: TFunction,
) => {
  if (!data) return [];

  return Object.keys(data).map((key) => ({
    label: t(`invoices.payment_methods.${key}`),
    value: data[key as keyof PaymentMethodStats],
  }));
};

export const getPaymentMethodsLegendChart = (
  data: PaymentMethodStats | undefined,
  t: TFunction,
) => {
  if (!data) return [];

  const total = Object.values(data).reduce((acc, val) => acc + val, 0);

  return Object.keys(data).map((key) => ({
    label: t(`invoices.payment_methods.${key}`),
    value: data[key as keyof PaymentMethodStats],
    percent: ((data[key as keyof PaymentMethodStats] / total) * 100).toFixed(0),
  }));
};
