import { Fragment } from 'react';
import { Box, Flex, Text, useColorModeValue, CardProps, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PieChart from '@/components/Charts/PieChart';
import { pieChartBg, pieChartOptions } from '@/components/Charts/config';
import { VSeparator } from '@/components/Separator';
import { FaDatabase } from 'react-icons/fa';

type Props = CardProps & {
  title?: string;
  data: { label: string; value: number }[];
  legend?: { label: string; percent: string }[];
  maxH?: string;
};

export default function Conversion({ title, data, legend, maxH, ...rest }: Props) {
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const _data = Object.values(data).map(({ value }) => value);
  const hasData = _data.length > 0 && _data.some((value) => value > 0);

  return (
    <Flex alignItems="center" flexDirection="column" w="100%" h="100%" {...rest}>
      <Flex
        px={{ base: '0px', '2xl': '10px' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text
          color={textColor}
          mt="4px"
          me="auto"
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          {title}
        </Text>
      </Flex>

      {hasData ? (
        <>
          <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
            <Flex maxH={maxH} h="100%">
              <PieChart
                h="100%"
                w="100%"
                chartData={_data}
                chartOptions={{
                  ...pieChartOptions,
                  labels: Object.values(data).map(({ label }) => label),
                }}
              />
            </Flex>
          </Flex>

          {legend && (
            <Flex w="100%" mt="15px" mx="auto" justifyContent="space-between">
              {legend.map(({ label, percent }, i) => (
                <Fragment key={label}>
                  <Flex direction="column" align="center" flex={1}>
                    <Flex align="center">
                      <Box
                        h="8px"
                        w="8px"
                        bg={pieChartBg[i]}
                        borderRadius="50%"
                        me="4px"
                      />

                      <Text
                        fontSize={{ base: '2xs', md: 'md' }}
                        color="secondaryGray.600"
                        isTruncated
                        w="100%"
                        fontWeight="700"
                      >
                        {label}
                      </Text>
                    </Flex>

                    <Text
                      pl="14px"
                      fontSize={{
                        base: 'xs',
                        md: 'md',
                      }}
                      color={textColor}
                      fontWeight="700"
                    >
                      {isNaN(+percent) ? 0 : percent}%
                    </Text>
                  </Flex>

                  {legend.length !== i + 1 && <VSeparator />}
                </Fragment>
              ))}
            </Flex>
          )}
        </>
      ) : (
        <Flex
          w="100%"
          h="100%"
          justify="center"
          align="center"
          mt="-20px"
          direction="column"
        >
          <Icon as={FaDatabase} fontSize={24} color="secondaryGray.600" />
          <Text color="secondaryGray.600" fontSize="lg" fontWeight="700">
            {t('common.no_data')}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
