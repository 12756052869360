import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  FormErrorMessage,
  Flex,
  Textarea,
  Text,
} from '@chakra-ui/react';

import Popup from '@/components/Popup';
import useRequestState from '@/hooks/useRequestState';
import { createAnnouncement, updateAnnouncement } from '@/api/announcement';
import { DEFAULT_FORM_DATA } from './config';

type Props = {
  data: Announcement | null | undefined;
  setSelected: (data: Announcement | null | undefined) => void;
  onSubmit: (response: Announcement) => void;
};

const formatWhatsAppMessage = (message: string) => {
  return message.replace(/[\n\r\t]+/g, '').replace(/\s{4,}/g, '');
};

const AnnouncementForm = ({ data, setSelected, onSubmit }: Props) => {
  const { t } = useTranslation();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Announcement | AnnouncementFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    values: data ?? DEFAULT_FORM_DATA,
  });

  const handleOnClose = (data?: null | undefined) => {
    reset();
    setSelected(data);
  };

  const handleSuccess = (data: Announcement) => {
    onSubmit(data);
    handleOnClose();
  };

  const { loading, trigger: submitTrigger } = useRequestState<Announcement>(
    () => {
      const body = getValues();

      if (data?._id) {
        return updateAnnouncement(data._id, body);
      }

      return createAnnouncement(body);
    },
    [],
    { condition: false, onSuccess: handleSuccess },
  );

  const formatMessageOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const formattedMessage = formatWhatsAppMessage(e.target.value);

    setValue('message', formattedMessage);
  };

  const message = watch('message');

  return (
    <Popup
      size="lg"
      isOpen={data !== undefined}
      onClose={() => handleOnClose(undefined)}
      title={
        data?._id
          ? t('announcements_form.edit_announcement')
          : t('announcements_form.create_announcement')
      }
      content={
        <form>
          <Stack spacing={4}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{
                base: 2,
                md: 5,
              }}
            >
              {/* Name */}
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{t('announcements_form.name')}</FormLabel>

                <Input {...register('name', { required: t('form.required') })} />

                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            {/* Message */}
            <FormControl isRequired isInvalid={!!errors.message}>
              <FormLabel>{t('announcements_form.message')}</FormLabel>

              <Textarea
                {...register('message', { required: t('form.required') })}
                maxLength={700}
                onChange={formatMessageOnChange}
                value={message || ''}
              />
              <Text fontSize="sm" color="gray.500" textAlign="right">
                {message?.length || 0}/700
              </Text>

              <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </form>
      }
      footer={
        <HStack>
          <Button
            disabled={loading}
            type="submit"
            variant="brand"
            onClick={handleSubmit(submitTrigger)}
          >
            {data?._id ? t('form.update') : t('form.save')}
          </Button>

          <Button
            disabled={loading}
            variant="outline"
            onClick={() => handleOnClose(undefined)}
          >
            {t('form.cancel')}
          </Button>
        </HStack>
      }
    />
  );
};

export default observer(AnnouncementForm);
