import moment, { Moment } from 'moment';

import { DATE_FILTERS, ORGANIZATION_DEFAULT_TRIAL_DAYS } from '@/constants/app';

export const changeToUtc = (date: Date) => {
  return moment(date).utcOffset(0, true).toDate();
};

export const stringDateToUtc = (date: string) => {
  return moment(date).utcOffset(0, true);
};

export const isToday = (date: Date) => {
  return moment(date).isSame(moment(), 'day');
};

export const getMomentDate = (date: number) => {
  return moment(date);
};

export const dateBetweenRange = (date: Moment, startDate: string, endDate: string) => {
  return (
    date.utcOffset(0, true).isSameOrBefore(moment(endDate)) &&
    date.isSameOrAfter(moment(startDate))
  );
};

export const dateIsWithinRange = (date: Date, addDays: number) => {
  return moment(date).isSameOrBefore(moment().add(addDays, 'days'));
};

export const addDays = (date: Date, days: number) => {
  return moment(date).add(days, 'days').toDate();
};

export const formatDate = (
  date: Date | undefined | null,
  format = 'DD-MM-YYYY',
  placeholder = '-',
) => {
  if (!date) return placeholder;

  return moment(date).utc().format(format);
};

export const formatDateWithTime = (
  date: Date | undefined | null,
  format = 'DD-MM-YYYY HH:mm',
  placeholder = '-',
) => {
  if (!date) return placeholder;

  return moment(date).utc().format(format);
};

export const formateDateLocal = (
  date: Date | undefined | null,
  format = 'DD-MM-YYYY',
  placeholder = '-',
) => {
  if (!date) return placeholder;

  return moment(date).format(format);
};

export const calculateShiftDate = (date: Date, shiftDay: number) => {
  const utcDate = moment(date);

  return utcDate.clone().add(shiftDay, 'days').toDate();
};

export const getDatesByFilter = (filter: DATE_FILTERS) => {
  const now = moment.utc();

  switch (filter) {
    case DATE_FILTERS.THIS_MONTH:
      return {
        startDate: now.clone().startOf('month').toDate(),
        endDate: now.clone().endOf('month').toDate(),
      };
    case DATE_FILTERS.LAST_MONTH:
      return {
        startDate: now.clone().subtract(1, 'month').startOf('month').toDate(),
        endDate: now.clone().subtract(1, 'month').endOf('month').toDate(),
      };
    case DATE_FILTERS.THIS_YEAR:
      return {
        startDate: now.clone().startOf('year').toDate(),
        endDate: now.clone().endOf('year').toDate(),
      };
    case DATE_FILTERS.LAST_YEAR:
      return {
        startDate: now.clone().subtract(1, 'year').startOf('year').toDate(),
        endDate: now.clone().subtract(1, 'year').endOf('year').toDate(),
      };
    default:
      return {
        startDate: now.clone().startOf('month').toDate(),
        endDate: now.clone().endOf('month').toDate(),
      };
  }
};

export const daysInMonthByPeriod = (date: CalendarValue | null) => {
  if (!date) return [];

  const [startDate, endDate] = date as [Date, Date];

  const start = moment(startDate); // Original start date
  const end = moment(endDate); // Original end date

  let result = [];
  let current = start.clone().startOf('month'); // Start from the beginning of the month

  while (current.isSameOrBefore(end, 'month')) {
    const totalDays = current.daysInMonth(); // Total days in the current month
    let leftDays;

    if (current.isSame(start, 'month') && current.isSame(end, 'month')) {
      // If start and end are in the same month
      leftDays = end.date() - start.date() + 1;
    } else if (current.isSame(start, 'month')) {
      // For the first month: days from the start date to the end of the month
      leftDays = totalDays - start.date() + 1;
    } else if (current.isSame(end, 'month')) {
      // For the last month: days from the 1st to the end date
      leftDays = end.date();
    } else {
      // Full months in between
      leftDays = totalDays;
    }

    result.push({
      total: totalDays,
      left: leftDays,
      month: current.month() + 1, // Month number (1-12)
      year: current.year(), // Year
    });

    // Move to the next month
    current.add(1, 'month');
  }

  return result;
};

export const getTrialDaysLeft = (
  startDate: Date,
  trialDays: number = ORGANIZATION_DEFAULT_TRIAL_DAYS,
) => {
  if (!startDate) return undefined;

  const end = addDays(startDate, trialDays);
  const data = moment(end).diff(moment(), 'days');

  return data >= 1 ? data : undefined;
};

export const timestampToDate = (timestamp: number) => {
  return moment.unix(timestamp);
};

export const timestampToFomattedDate = (timestamp: number, format = 'DD-MM-YYYY') => {
  const date = timestampToDate(timestamp);

  return formatDate(date.toDate(), format);
};
