import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ChangeEvent, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

import { TELEGRAM_BOT_URL } from '@/constants/app';
import { CheckIcon, CopyIcon, DownloadIcon } from '@chakra-ui/icons';

type Props = {
  data?: Client | null;
};

const CommunicationForm = ({ data }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation();

  const { watch, register, setValue } = useFormContext<ClientFormData>();

  const telegram = watch('telegram');
  const email = watch('email');
  const phone = watch('phone');
  const selectedMessenger = watch('selectedMessenger');

  const handleDownload = () => {
    const canvas = document.querySelector('canvas');

    if (!canvas) return;

    const link = document.createElement('a');
    link.download = 'telegram-qr.png';
    link.href = canvas.toDataURL('image/png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(TELEGRAM_BOT_URL);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  const handleMessengerChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const data = e.target.value as 'telegram' | 'whatsapp' | '-';

    setValue('selectedMessenger', data);

    if (data === '-') {
      setValue('telegram', null);
      setValue('telegramPhone', null);
      setValue('whatsapp', null);
    } else if (data === 'telegram') {
      setValue('telegramPhone', phone);
      setValue('whatsapp', null);
    } else if (data === 'whatsapp') {
      setValue('whatsapp', phone);
      setValue('telegramPhone', null);
      setValue('telegram', null);
    }
  };

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="column">
        <Flex gap={{ base: 4, md: 2 }} direction={{ base: 'column', md: 'row' }}>
          <FormControl>
            <FormLabel>{t('clients_form.email')}</FormLabel>

            <Flex alignItems="center" gap={2}>
              <Input disabled value={email} />
            </Flex>
          </FormControl>

          <FormControl>
            <FormLabel>{t('clients_form.messenger')}</FormLabel>

            <Select value={selectedMessenger} onChange={handleMessengerChange}>
              <option value="-">-</option>
              <option value="telegram">Telegram</option>
              <option value="whatsapp">WhatsApp</option>
            </Select>
          </FormControl>
        </Flex>

        {selectedMessenger === 'telegram' && !telegram && (
          <>
            <FormControl mt="20px" gap="20px">
              <FormLabel>{t('clients_form.telegram_phone')}</FormLabel>

              <Input
                defaultValue={phone}
                type="number"
                placeholder={t('481234567890')}
                {...register('telegramPhone')}
              />
            </FormControl>

            <Flex direction="row" mt="20px" gap="20px">
              <QRCodeCanvas size={200} value={TELEGRAM_BOT_URL} />

              <Flex gap="20px" direction="column">
                <Text>{t('clients_form.telegram_qr_text')}</Text>

                <Flex gap="20px">
                  <Button
                    onClick={handleDownload}
                    leftIcon={<DownloadIcon />}
                    variant="brand"
                  >
                    {t('common.download')}
                  </Button>

                  <Button
                    onClick={handleCopy}
                    leftIcon={isCopied ? <CheckIcon /> : <CopyIcon />}
                  >
                    {isCopied ? t('common.copied') : t('common.copy')}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}

        {selectedMessenger === 'telegram' && telegram && (
          <Flex
            direction="row"
            alignItems="center"
            gap="10px"
            mt="20px"
            justifyContent="center"
          >
            <CheckIcon color="green.300" fontSize="20px" />
            <Text fontSize="lg" fontWeight="bold">
              {t('clients_form.telegram_already_connected')}
            </Text>
          </Flex>
        )}

        {selectedMessenger === 'whatsapp' && (
          <FormControl mt="20px" gap="20px">
            <FormLabel>{t('clients_form.whatsapp_number')}</FormLabel>

            <Input
              defaultValue={phone}
              type="number"
              placeholder={t('481234567890')}
              {...register('whatsapp')}
            />
          </FormControl>
        )}
      </Flex>
    </Stack>
  );
};

export default CommunicationForm;
