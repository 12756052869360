import {
  Stack,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tooltip,
  Text,
  Input,
  SimpleGrid,
  Checkbox,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { calculateShiftDate, formatDate } from '@/utils/date';
import useRequestState from '@/hooks/useRequestState';
import { getStripeList } from '@/api/stripe';
import {
  CURRENCY,
  PAYMENT_CIRCLE,
  PAYMENT_SHIFT_DAY,
  WEEK_DAYS_NUMBER,
  SERVICE_FEE,
} from '@/constants/app';

const PropertyPayments = () => {
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<PropertyFormData>();

  const paymentShiftValue = watch('paymentShiftDay');
  const monthlyInvoicingShiftDayValue = watch('monthlyInvoicingShiftDay');
  const _isManualInvoicing = watch('isManualInvoicing');
  const circle = watch('paymentCircle');
  const stripe = watch('stripe');
  const serviceFee = watch('serviceFee');

  const [differentInvoicingDay, setDifferentInvoicingDay] = useState<boolean>(
    paymentShiftValue !== monthlyInvoicingShiftDayValue,
  );

  // @ts-ignore
  const isManualInvoicing = _isManualInvoicing === 'true' || _isManualInvoicing === true;
  const isMonthly = circle === PAYMENT_CIRCLE.MONTHLY;
  const isWeekly = circle === PAYMENT_CIRCLE.WEEKLY;
  const isDaily = circle === PAYMENT_CIRCLE.DAILY;

  useEffect(() => {
    setValue('isManualInvoicing', isDaily);
  }, [isDaily, setValue]);

  const { data: stripeData, loading: stripeLoading } = useRequestState<StripeAccountList>(
    getStripeList,
    [],
    {
      onSuccess: (data) => {
        const noStripe = stripe === '' || stripe === null;

        if (data.length && !noStripe) {
          return setValue('stripe', data[0]._id);
        }

        setValue('stripe', null);
      },
    },
  );

  return (
    <Stack spacing={4}>
      <Flex gap={{ base: 4, md: 2 }} direction="row">
        {/* Currency */}
        <FormControl isRequired isInvalid={!!errors.currency}>
          <FormLabel>{t('property_form.currency')}</FormLabel>

          <Select
            placeholder={t('property_form.select_currency')}
            {...register('currency', { required: t('form.required') })}
          >
            {Object.values(CURRENCY).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>

          <FormErrorMessage>
            <>{errors.currency?.message}</>
          </FormErrorMessage>
        </FormControl>

        {/* Stripe */}
        <FormControl isInvalid={!!errors.stripe}>
          <FormLabel>{t('property_form.stripe_accounts')}</FormLabel>

          <Select
            {...register('stripe')}
            disabled={stripeLoading}
            value={stripe ?? ''}
            onChange={(e) => {
              setValue('stripe', e.target.value);
            }}
          >
            {stripeData?.map((stripe) => (
              <option key={stripe._id} value={stripe._id}>
                {stripe.name}
              </option>
            ))}

            <option value="">{t('property_form.no_stripe_accounts')}</option>
          </Select>

          <FormErrorMessage>
            <>{errors.stripe?.message}</>
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction="row">
        {/* Manual invoicing */}
        <FormControl isRequired>
          <FormLabel>{t('properties.manual_invoicing')}</FormLabel>

          <Select {...register('isManualInvoicing')} disabled={isDaily}>
            <option value="true">{t('common.yes')}</option>
            <option value="false">{t('common.no')}</option>
          </Select>
        </FormControl>

        {/* Invoice expiration */}
        <FormControl isRequired isInvalid={!!errors.invoiceExpirationDays}>
          <FormLabel>{t('property_form.invoice_expiration_days')}</FormLabel>

          <Input
            type="number"
            {...register('invoiceExpirationDays', { required: t('form.required') })}
          />

          <FormErrorMessage>{errors.invoiceExpirationDays?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction="row">
        <FormControl isRequired isInvalid={!!errors.serviceFee}>
          <FormLabel>{t('properties.service_fee')}</FormLabel>

          <Slider
            min={SERVICE_FEE.MIN}
            max={SERVICE_FEE.MAX}
            step={0.1}
            value={serviceFee ?? SERVICE_FEE.DEFAULT}
            onChange={(val) => setValue('serviceFee', val)}
          >
            <Flex position="relative" w="100%" mt={5}>
              <SliderMark value={SERVICE_FEE.MIN}>{SERVICE_FEE.MIN}</SliderMark>

              <SliderMark value={SERVICE_FEE.MAX} transform="translateX(-100%)">
                {SERVICE_FEE.MAX}
              </SliderMark>
            </Flex>

            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>

            <Tooltip
              hasArrow
              isOpen
              bg="brand.500"
              color="white"
              placement="top"
              label={`${serviceFee}%`}
            >
              <SliderThumb />
            </Tooltip>
          </Slider>

          <FormErrorMessage>{errors.serviceFee?.message}</FormErrorMessage>
        </FormControl>

        <Flex align="center" justify="center" w="100%" p="10px">
          <Text>{t('property_form.service_fee_info')}</Text>
        </Flex>
      </Flex>

      <Flex gap={{ base: 4, md: 2 }} direction="row">
        <FormControl isRequired isInvalid={!!errors.paymentCircle}>
          <FormLabel>{t('properties.payment_circle')}</FormLabel>

          <Select {...register('paymentCircle', { required: t('form.required') })}>
            {Object.values(PAYMENT_CIRCLE).map((circle) => (
              <option key={circle} value={circle}>
                {t(`properties.payment_circles.${circle}`)}
              </option>
            ))}
          </Select>

          <FormErrorMessage>
            <>{errors.paymentCircle?.message}</>
          </FormErrorMessage>
        </FormControl>
      </Flex>

      {isWeekly && !isManualInvoicing && (
        <FormControl isRequired isInvalid={!!errors.paymentShiftWeekDay}>
          <FormLabel>{t('properties.payment_shift_weekly')}</FormLabel>

          <Select
            {...register('paymentShiftWeekDay', {
              required: t('form.required'),
              setValueAs: (val) => Number(val),
            })}
          >
            {WEEK_DAYS_NUMBER.map((day) => (
              <option key={day} value={day}>
                {t(`week_days_by_number.${day}`)}
              </option>
            ))}
          </Select>

          <FormErrorMessage>
            <>{errors.paymentShiftWeekDay?.message}</>
          </FormErrorMessage>
        </FormControl>
      )}

      {isMonthly && !isManualInvoicing && (
        <>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} alignItems="center">
            <FormControl isRequired isInvalid={!!errors.paymentShiftDay}>
              <FormLabel>{t('property_form.payment_term')}</FormLabel>

              <Slider
                mt="15px"
                min={PAYMENT_SHIFT_DAY.MIN}
                max={PAYMENT_SHIFT_DAY.MAX}
                value={paymentShiftValue}
                onChange={(val) => {
                  setValue('paymentShiftDay', val);

                  if (differentInvoicingDay) {
                    setValue('monthlyInvoicingShiftDay', val - 1);
                  } else {
                    setValue('monthlyInvoicingShiftDay', val);
                  }
                }}
              >
                <Flex position="relative" w="100%" mt={5}>
                  <SliderMark value={PAYMENT_SHIFT_DAY.MIN}>
                    {PAYMENT_SHIFT_DAY.MIN}
                  </SliderMark>

                  <SliderMark
                    value={PAYMENT_SHIFT_DAY.DEFAULT}
                    transform="translateX(-4px)"
                  >
                    {PAYMENT_SHIFT_DAY.DEFAULT}
                  </SliderMark>

                  <SliderMark value={PAYMENT_SHIFT_DAY.MAX} transform="translateX(-100%)">
                    {PAYMENT_SHIFT_DAY.MAX}
                  </SliderMark>
                </Flex>

                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>

                <Tooltip
                  hasArrow
                  isOpen
                  bg="brand.500"
                  color="white"
                  placement="top"
                  label={`${paymentShiftValue}`}
                >
                  <SliderThumb />
                </Tooltip>
              </Slider>

              <FormErrorMessage>{errors.paymentShiftDay?.message}</FormErrorMessage>
            </FormControl>

            <Flex direction="column" mt="10px">
              <Text fontWeight="bold" textAlign="center">
                {t('property_form.payment_shift_example')}:
              </Text>

              <Flex direction="row" gap="20px" justify="center">
                <Flex>{shiftedDifferentMonths(paymentShiftValue).january}</Flex>
                <Flex>{shiftedDifferentMonths(paymentShiftValue).february}</Flex>
                <Flex>{shiftedDifferentMonths(paymentShiftValue).april}</Flex>
              </Flex>
            </Flex>
          </SimpleGrid>

          <FormControl mt="15px">
            <Checkbox
              isChecked={differentInvoicingDay}
              onChange={(e) => {
                setDifferentInvoicingDay(e.target.checked);

                setValue(
                  'monthlyInvoicingShiftDay',
                  e.target.checked ? paymentShiftValue - 1 : paymentShiftValue,
                );
              }}
            >
              {t('property_form.invoicing_in_advance')}
            </Checkbox>
          </FormControl>

          {differentInvoicingDay && (
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} alignItems="center">
              <FormControl isRequired isInvalid={!!errors.monthlyInvoicingShiftDay}>
                <FormLabel>{t('property_form.invoicing_day')}</FormLabel>

                <Slider
                  mt="15px"
                  min={PAYMENT_SHIFT_DAY.MIN}
                  max={paymentShiftValue - 1}
                  value={monthlyInvoicingShiftDayValue!}
                  onChange={(val) => setValue('monthlyInvoicingShiftDay', val)}
                >
                  <Flex position="relative" w="100%" mt={5}>
                    <SliderMark value={PAYMENT_SHIFT_DAY.MIN}>
                      {PAYMENT_SHIFT_DAY.MIN}
                    </SliderMark>

                    <SliderMark
                      value={paymentShiftValue - 1}
                      transform="translateX(-100%)"
                    >
                      {paymentShiftValue - 1}
                    </SliderMark>
                  </Flex>

                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>

                  <Tooltip
                    hasArrow
                    isOpen
                    bg="brand.500"
                    color="white"
                    placement="top"
                    label={`${monthlyInvoicingShiftDayValue}`}
                  >
                    <SliderThumb />
                  </Tooltip>
                </Slider>

                <FormErrorMessage>
                  {errors.monthlyInvoicingShiftDay?.message}
                </FormErrorMessage>
              </FormControl>

              <Flex direction="column" mt="10px">
                <Text fontWeight="bold" textAlign="center">
                  {t('property_form.payment_shift_example')}:
                </Text>

                <Flex direction="row" gap="20px" justify="center">
                  <Flex>
                    {shiftedDifferentMonths(monthlyInvoicingShiftDayValue!).january}
                  </Flex>
                  <Flex>
                    {shiftedDifferentMonths(monthlyInvoicingShiftDayValue!).february}
                  </Flex>
                  <Flex>
                    {shiftedDifferentMonths(monthlyInvoicingShiftDayValue!).april}
                  </Flex>
                </Flex>
              </Flex>
            </SimpleGrid>
          )}
        </>
      )}
    </Stack>
  );
};

const shiftedDifferentMonths = (shiftDay: number) => {
  const january = calculateShiftDate(new Date('2023-02-01T15:00:00'), shiftDay);
  const february = calculateShiftDate(new Date('2023-03-01T15:00:00'), shiftDay);
  const april = calculateShiftDate(new Date('2023-04-01T15:00:00'), shiftDay);

  return {
    february: formatDate(february, 'DD MMM'),
    january: formatDate(january, 'DD MMM'),
    april: formatDate(april, 'DD MMM'),
  };
};

export default PropertyPayments;
