import { useMemo, useState } from 'react';
import { Box, Card, Flex, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { FaDollarSign, FaFileContract, FaPeopleCarry } from 'react-icons/fa';
import { GiPayMoney } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import { TbClockExclamation } from 'react-icons/tb';
import { observer } from 'mobx-react-lite';

import PieCard from '@/components/PieCard';
import usePageInfo from '@/hooks/usePageInfo';
import useRequestState from '@/hooks/useRequestState';
import { propertyStats } from '@/api/stats';
import ActionButton from '@/components/ActionButton';
import AppStore from '@/stores/app';
import { getInvoiceChartData, getPaymentMethodsChartData } from '@/utils/invoices';
import { getContractChartData } from '@/utils/contracts';
import { getDatesByFilter } from '@/utils/date';
import TotalSpent from '@/components/TotalSpent';
import Dropdown from '@/components/DropDown';
import { DATE_FILTERS } from '@/constants/app';
import { MdMoneyOff } from 'react-icons/md';
import ContractSimpleTable from '../Contracts/components/ContractTable';

const dateFilters = {
  [DATE_FILTERS.THIS_MONTH]: {
    key: 'date_filters.this_month',
    value: DATE_FILTERS.THIS_MONTH,
  },
  [DATE_FILTERS.LAST_MONTH]: {
    key: 'date_filters.last_month',
    value: DATE_FILTERS.LAST_MONTH,
  },
  [DATE_FILTERS.THIS_YEAR]: {
    key: 'date_filters.this_year',
    value: DATE_FILTERS.THIS_YEAR,
  },
  [DATE_FILTERS.LAST_YEAR]: {
    key: 'date_filters.last_year',
    value: DATE_FILTERS.LAST_YEAR,
  },
};

const HomePage = () => {
  usePageInfo({ title: 'pages.home' });

  const { t } = useTranslation();
  const dropdownbg = useColorModeValue('white', 'whiteAlpha.100');

  const [selectedDateFilter, setSelectedDateFilter] = useState<
    valueof<typeof DATE_FILTERS>
  >(dateFilters[DATE_FILTERS.THIS_MONTH].value);

  const { property } = AppStore;

  const { data } = useRequestState<PropertyStats>(
    () => {
      const filter = getDatesByFilter(selectedDateFilter);

      return propertyStats({
        startDate: filter.startDate,
        endDate: filter.endDate,
        isYearly: isYearly(selectedDateFilter),
      });
    },
    [selectedDateFilter, property?._id],
    { condition: !!property?._id },
  );

  const invoiceData = useMemo(
    () => ({
      data: getInvoiceChartData(data?.invoices, t),
      // legends: getInvoicesLegendChart(data?.invoices, t),
    }),
    [data?.invoices, t],
  );

  const paymentMethodsData = useMemo(
    () => ({
      data: getPaymentMethodsChartData(data?.paymentTypes, t),
      // legends: getPaymentMethodsLegendChart(data?.paymentTypes, t),
    }),
    [data?.paymentTypes, t],
  );

  const contractData = useMemo(
    () => ({
      data: getContractChartData(data?.contracts, t),
      // legends: getContractLegendChart(data?.contracts, t),
    }),
    [data?.contracts, t],
  );

  return (
    <Flex mb="20px" flex={1} direction="column">
      <Box mb="20px">
        <Dropdown
          translate
          bg={dropdownbg}
          width={200}
          bgActive="brand.500"
          menuVariant="solid"
          items={Object.values(dateFilters)}
          selectedItem={dateFilters[selectedDateFilter]}
          placeholder=""
          labelKey="key"
          valueKey="value"
          onSelect={(item) => setSelectedDateFilter(item.value)}
        />
      </Box>

      <SimpleGrid columns={{ base: 2, md: 3, lg: 5, '2xl': 6 }} gap="20px" mb="20px">
        <ActionButton
          mini
          hoverable={false}
          name={t('home.clients')}
          value={`${data?.clientsCount ?? '-'}`}
          icon={FaPeopleCarry}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.new_contracts')}
          value={`${data?.activeContractCount ?? '-'}`}
          icon={FaFileContract}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.revenue')}
          value={`${data?.revenue.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={FaDollarSign}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.unpaid_revenue')}
          value={`${data?.unpaidRevenue.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={MdMoneyOff}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.refunded_revenue')}
          value={`${data?.refundedRevenue.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={GiPayMoney}
        />

        <ActionButton
          mini
          hoverable={false}
          name={t('home.late_payment_penalties')}
          value={`${data?.unpaidPenalties.toFixed(0) ?? '-'} ${property?.currency}`}
          icon={TbClockExclamation}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2, '3xl': 3 }} gap="20px" mb="20px">
        <Card
          gridColumn={{ base: 'span 1', md: 'span 2', xl: 'span 2', '3xl': 'span 1' }}
        >
          <TotalSpent
            title={t(dateFilters[selectedDateFilter]['key'])}
            isYearly={isYearly(selectedDateFilter)}
            data={[
              { name: t('home.revenue'), data: data?.paidVsUnpaidMonthly?.paid ?? [] },
              {
                name: t('home.unpaid_revenue'),
                data: data?.paidVsUnpaidMonthly?.unpaid ?? [],
              },
              {
                name: t('home.refunded_revenue'),
                data: data?.paidVsUnpaidMonthly?.refunded ?? [],
              },
            ]}
          />
        </Card>

        <Flex direction={{ base: 'column', md: 'row' }} gap="20px" gridColumn="span 2">
          <Card>
            <PieCard
              title={t('home.paid_by_types')}
              data={paymentMethodsData.data}
              maxH="180px"
            />
          </Card>

          <Card>
            <PieCard title={t('home.invoices')} data={invoiceData.data} maxH="180px" />
          </Card>

          <Card>
            <PieCard title={t('home.contracts')} data={contractData.data} maxH="180px" />
          </Card>
        </Flex>
      </SimpleGrid>

      <ContractSimpleTable
        flex={1}
        data={data?.expiringContracts ?? []}
        title={t('home.expiring_contracts')}
      />
    </Flex>
  );
};

const isYearly = (dateFilter: valueof<typeof DATE_FILTERS>) =>
  dateFilter === DATE_FILTERS.THIS_YEAR || dateFilter === DATE_FILTERS.LAST_YEAR;

export default observer(HomePage);
