import { DOCUMENT_TYPE } from '@/constants/app';

export const DEFAULT_FORM_DATA: ClientFormData = {
  name: '',
  lastName: '',
  language: 'en',
  dob: '2000-01-01',
  email: '',
  phone: '',
  telegram: null,
  telegramPhone: null,
  whatsapp: null,
  selectedMessenger: '-',
  document: {
    type: DOCUMENT_TYPE.PASSPORT,
    number: '',
    issued: '2020-01-01',
    expires: '2030-01-01',
  },
};
